import { useMemo } from 'react';
import { Honeycomb } from '../svgs';

export const Lineup = ({ refs, pageHeight }) => {
  const { lineupRef, homeRef } = refs || {};

  return (
    <div
      ref={lineupRef}
      style={{ background: 'radial-gradient(#1B0805 45%, #0E0E06 90%)' }}
      className="absolute top-0 w-full z-20"
    >
      <div className="h-4 w-full bg-black border-b border-yellow-900 border-opacity-50" />

      <Honeycomb
        key="firstHoney"
        className="w-screen h-full absolute stroke-current text-yellow-900 opacity-40 stroke-3 z-0 pb-8"
      />
      <div className="w-full flex flex-col lg:flex-row items-center">
        <div
          style={{ height: pageHeight }}
          className="order-2 lg:order-1 flex-1 flex flex-col items-center justify-center py-28 lg:py-36 z-10"
        >
          <img alt="lineup" src="/images/BeltaneFlyer2023Md.jpg" className="w-auto max-h-full" />
        </div>
        <div className="order-1 lg:order-2 flex-1 flex flex-col items-center justify-center h-full pt-28 lg:pt-0 z-10">
          <div className="w-full flex-1 flex flex-col items-center space-y-12 px-8">
            <h1 className="text-white text-center ml-10 text-7xl lg:text-9xl font-light font-serif">Lineup</h1>
            <div className="my-10 flex-1 border-t-2 w-2/3 max-w-sm" />
            <div className="flex-1 h-full lg:grid lg:grid-cols-2 items-center space-y-5 text-center">
              <h3 className="text-5xl text-white col-span-2 py-4">World's Finest</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Pimps of Joytime</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Holly Bowling</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">High Step Society</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Plantrae</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Cytrus</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Brad Parsons & The Quick and Easy Boys</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Band of Comerados</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Banshee Tree</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Threedom</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Spunj</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Reb & The Good News</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Ben Larsen</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Ashleigh Flynn & The Riveters</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Violet Pilot</h3>
              <h3 className="text-3xl text-white text-center lg:text-left">Bodhi Mojo</h3>
              <h3 className="text-3xl text-white text-center lg:text-right">Espacio Flamenco</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="h-4 w-full bg-black border-t border-yellow-900 border-opacity-50" />

      <div className="absolute h-8 -mb-8 w-full bottom-0 opacity-50 bg-gradient-to-b from-yellow-900 mix-blend-lighten" />
    </div>
  );
};
