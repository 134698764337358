import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { GiFlowerEmblem } from 'react-icons/gi';

import { confirmPayment } from '../graphql/mutations';

export const TicketsCheckoutForm = ({ ticket, setTicket, secret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (elements) {
      elements.getElement(PaymentElement).on('ready', () => setIsLoading(false));
    }
  }, [elements]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!secret) return;

    stripe.retrievePaymentIntent(secret).then(({ paymentIntent }) => {
      console.log(paymentIntent);
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, secret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error, paymentIntent } = await stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then((resp) => {
        console.log(`resp`, resp);
        setSubmitted(true);

        return resp;
      });

    console.log(paymentIntent);
    if (paymentIntent) {
      const input = { id: ticket?.id };

      API.graphql({ query: confirmPayment, variables: { input } })
        .then(({ data: { confirmPayment: resp } }) => (resp ? JSON.parse(resp) : {}))
        .then(setTicket);
    }

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occured.');
      }
    }

    setIsLoading(false);
  };

  const disabled = isLoading || !stripe || !elements;

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        {isLoading && (
          <div className="w-full flex justify-center">
            <GiFlowerEmblem style={{ animationDuration: '5s' }} className="text-white w-12 h-12 animate-spin" />
          </div>
        )}
        {!isLoading && (
          <button
            id="submit"
            type="submit"
            disabled={disabled}
            className="text-lg text-white bg-sky-700 hover:bg-blue-800 w-full rounded-lg shadow p-3 my-4"
          >
            <h2>Pay Now</h2>
          </button>
        )}
        {message && submitted && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
};
