import { GiForestCamp, GiDrumKit, GiDirectionSigns, GiTicket } from 'react-icons/gi';
import { HiOutlineMenu } from 'react-icons/hi';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { API } from 'aws-amplify';

const HeaderButton = ({ scrollTo, toRef, children, className, offset = 0 }) => {
  const onClick = () => scrollTo(toRef, offset);

  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex items-center space-x-2 text-base lg:text-lg text-white hover:text-blue-300 ${className}`}
    >
      {children}
    </button>
  );
};

const TicketButton = ({ children, onClick, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={`flex items-center space-x-2 text-base lg:text-lg text-white hover:text-blue-300 ${className}`}
  >
    {children}
  </button>
);

export const Header = ({ refs, scrollTo, setMenuOpen, menuOpen, setTicketsOpen, ticketsOpen }) => {
  const { homeRef, lineupRef, campingRef, scheduleRef, directionsRef } = refs || {};

  const onTicketClick = () => {
    setTicketsOpen(!ticketsOpen);
  };

  return (
    <div className="z-40 w-full shadow fixed flex flex-col">
      <div className="hidden md:flex w-full h-20 bg-gray-900 items-center justify-between px-4 text-white">
        <button type="button" onClick={() => scrollTo(homeRef)} className="ml-8">
          <h1 className="text-4xl lg:text-5xl text-white hover:text-blue-300 font-serif">Beltane</h1>
        </button>
        <div className="flex items-center space-x-4 px-8">
          <div className="flex items-center space-x-6 px-4">
            <HeaderButton scrollTo={scrollTo} toRef={lineupRef} offset={20}>
              <GiDrumKit className="w-6 h-6" />
              <h2>Lineup</h2>
            </HeaderButton>
            <HeaderButton scrollTo={scrollTo} toRef={campingRef} offset={-1}>
              <GiForestCamp className="w-6 h-6" />
              <h2 className="hidden lg:block">Camping</h2>
              <h2 className="block lg:hidden">Camp</h2>
            </HeaderButton>
            <HeaderButton scrollTo={scrollTo} toRef={directionsRef} offset={0}>
              <GiDirectionSigns className="w-6 h-6" />
              <h2 className="hidden lg:block">Directions</h2>
              <h2 className="block lg:hidden">Map</h2>
            </HeaderButton>
            <HeaderButton scrollTo={scrollTo} toRef={scheduleRef} offset={window.screen.height}>
              <AiOutlineUnorderedList className="w-6 h-6" />
              <h2>Schedule</h2>
            </HeaderButton>
          </div>
          {/* <div
            onClick={onTicketClick}
            className="items-center space-x-2 text-lg text-white space-x-3 bg-sky-700 hover:bg-blue-800 rounded-full p-3 px-4 shadow hidden lg:flex"
          >
            <h2>Tickets Coming Soon</h2>
            <GiTicket className="w-6 h-6" />
          </div> */}
          <TicketButton onClick={onTicketClick} className="space-x-3 bg-sky-700 rounded-full p-3 shadow flex">
            <span className="text-base">Buy Tickets</span>
            <GiTicket className="w-7 h-7 relative left-px text-white" />
          </TicketButton>
        </div>
      </div>
      <div className="md:hidden w-full h-20 bg-gray-900 flex items-center justify-between px-5 text-white">
        <button type="button" onClick={() => setMenuOpen(!menuOpen)} className="flex items-center space-x-5 ml-1">
          <HiOutlineMenu className="w-10 h-10 text-white" />
        </button>
        <h1 className="ml-6 text-4xl text-white font-serif">Beltane</h1>
        {/* <span type="span" className="flex items-center space-x-2 text-base lg:text-lg text-white hover:text-blue-300">
          tickets coming soon
        </span> */}
        <TicketButton onClick={onTicketClick} className="space-x-3 bg-sky-700 rounded-full p-3 shadow flex">
          <GiTicket className="w-7 h-7 relative left-px text-white" />
        </TicketButton>
      </div>
    </div>
  );
};
