import { useState, useEffect } from 'react';

export const Home = ({ refs, pageHeight }) => {
  const { homeRef } = refs || {};
  const [backgroundImage, setBackgroundImage] = useState(false);

  useEffect(() => {
    const src = '/images/BeltanePoleCelebration.jpeg';
    const imageLoader = new Image();

    imageLoader.src = src;
    imageLoader.onload = () => setBackgroundImage(`url(${src})`);
  }, []);

  return (
    <div
      ref={homeRef}
      style={{ backgroundImage, height: pageHeight }}
      className="w-full bg-cover bg-center sticky top-0 bg-gray-800"
    >
      <div className="w-full h-full flex items-center justify-center">
        <FlowerPanel />
      </div>
    </div>
  );
};

const FlowerPanel = () => {
  const [backgroundImage, setBackgroundImage] = useState(false);

  useEffect(() => {
    const src = '/images/BeltaneFlower.png';
    const imageLoader = new Image();

    imageLoader.src = src;
    imageLoader.onload = () => setBackgroundImage(`url(${src})`);
  }, []);

  const textShadow = '0 4px 6px rgba(0,0,0,0.9)';

  return (
    <div
      style={{ backgroundImage, textShadow }}
      className="h-full max-w-screen-sm bg-no-repeat bg-contain bg-center bg-opacity-25 flex flex-col items-center justify-center text-white font-serif font-black"
    >
      <h2 className="w-full text-right text-3xl lg:text-5xl">May 5th-7th</h2>
      <h1 className="text-7xl lg:text-9xl">Beltane</h1>
      <div className="w-full flex items-center justify-between">
        <div className="flex flex-col items-center">
          <h3 className="text-2xl lg:text-4xl">Neal Creek</h3>
          <h3 className="text-2xl lg:text-4xl -mt-1">Scio, OR</h3>
        </div>
        <h1 className="text-5xl lg:text-8xl">2023</h1>
      </div>
    </div>
  );
};
