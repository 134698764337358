import { animated, useSpring, useSpringRef } from 'react-spring';
import { GiCampingTent, GiMineWagon, GiBowlOfRice, GiWaterDrop, GiBooze } from 'react-icons/gi';
import { MdOutlineFollowTheSigns } from 'react-icons/md';
import { FaCloudSunRain, FaToiletPaper, FaShower } from 'react-icons/fa';
import { TentAnimation } from './TentAnimation';

export const Camping = ({ refs, campingStyle }) => {
  const smallScreen = window.screen.width < 1024;
  const panelLgRef = useSpringRef();

  const scrollViewHeight = smallScreen ? `450vh` : `250vh`;
  const textShadow = '0 4px 6px rgba(35, 42, 70, 0.45)';

  return (
    <div ref={refs?.campingRef} className="relative">
      <div style={{ height: scrollViewHeight }} className="w-full" />
      <div className="z-10 fixed top-24 left-0 flex justify-center w-full pointer-events-none">
        <animated.h1
          style={{ opacity: campingStyle.opacity, textShadow }}
          className="z-10 text-4xl lg:text-7xl font-serif pl-4 top-2 lg:top-20 -mt-2 lg:-mt-20 left-px lg:left-3 relative text-yellow-600"
        >
          camping
        </animated.h1>
        <CampingPanel1 campingStyle={campingStyle} />
      </div>
      <div className="z-0 fixed h-full w-full top-0 right-0 text-9xl pointer-events-none">
        <div className="absolute transform z-0 -skew-x-6 -ml-20 h-full overflow-hidden">
          <animated.div
            style={{ width: '200vw', x: campingStyle.x }}
            className="h-full bg-opacity-40 overflow-hidden bg-white"
          />
        </div>
        <CampingPanelLg panelRef={panelLgRef} campingStyle={campingStyle} />
        <CampingPanelSm campingStyle={campingStyle}>
          <CampingSm1 campingStyle={campingStyle} />
          <CampingSm2 campingStyle={campingStyle} />
          <VendingSm1 campingStyle={campingStyle} />
          <WeatherSm1 campingStyle={campingStyle} />
        </CampingPanelSm>
      </div>
    </div>
  );
};

const CampingPanel1 = ({ campingStyle }) => (
  <animated.div style={{ opacity: campingStyle.opacity }} className="absolute self-center -top-32 pointer-events-none">
    <div className="h-full w-full flex flex-col justify-start items-center pt-8">
      <div className="w-56 h-56 lg:w-64 lg:h-64 bg-gray-900 rounded-full shadow-lg bg-opacity-95">
        <div className="w-full h-full flex flex-col justify-center items-center text-center p-12 xl:p-20">
          <div className="flex flex-col space-y-4 items-center">
            <TentAnimation className="w-56 h-56 lg:w-64 lg:h-64" />
          </div>
        </div>
      </div>
    </div>
  </animated.div>
);

const CampingPanelLg = ({ className, panelRef, campingStyle }) => {
  const style = useSpring({ opacity: 0, ref: panelRef });

  return (
    <animated.div
      style={{ opacity: campingStyle.opacity }}
      className={`hidden lg:block absolute h-full w-full p-12 ${className}`}
    >
      <div className="w-full h-full flex items-start mt-20 justify-center">
        <div className="w-full max-w-screen-xl p-6 bg-gray-900 rounded-xl">
          <div className="w-full flex flex-col items-center justify-start space-y-4 bg-white rounded-xl p-8">
            <div className="flex flex-col space-y-2 items-center w-full">
              <div className="flex-1 w-full flex flex-col items-center space-y-2 px-3 text-center mt-20">
                <h3 className="text-lg w-full font-semibold">
                  There are many beautiful places for you to camp at Neal Creek.
                </h3>
                <h3 className="text-sm w-full">
                  These areas will be marked and there will be some lovely people to help you figure out where you might
                  want to be.
                </h3>
              </div>
            </div>
            <div className="flex items-center flex-1">
              <ul className="list-disc w-full text-left space-y-2">
                <li className="text-sm ml-7 mr-2">
                  Tent camping only. <span className="font-semibold whitespace-nowrap">No RVs</span>
                </li>
                <li className="text-sm ml-7 mr-2">
                  Remember to <span className="font-semibold whitespace-nowrap">pack light</span>
                </li>
                <li className="text-sm ml-7 mr-2">
                  <span className="font-semibold whitespace-nowrap">Pack out</span> what you
                  <span className="font-semibold whitespace-nowrap"> pack in</span>
                </li>
              </ul>
            </div>
            <div className="w-full flex justify-center py-4">
              <div className="border-gray-200 border-t-2 w-2/3 max-w-sm" />
            </div>

            <div className="flex flex-col items-center flex-1 space-y-2 justify-center">
              <MdOutlineFollowTheSigns className="text-sky-400 w-12 h-12" />
              <div className="flex-1 w-full flex flex-col space-y-2 items-center justify-start text-center">
                <h3 className="text-sm w-full font-semibold">You will have to carry your stuff a short distance.</h3>
                <h3 className="text-sm w-full max-w-sm">
                  We will have a few carts & wagons at your service to make this chore more enjoyable!
                </h3>
              </div>
            </div>
            <div className="w-full flex flex-col lg:grid lg:grid-cols-2 space-y-2 justify-start max-w-screen-lg">
              <div className="flex items-center">
                <div className="flex-1 w-full flex space-x-8 items-center justify-start">
                  <GiWaterDrop className="text-sky-400 w-12 h-12" />
                  <div className="flex-1 w-full flex flex-col items-center justify-evenly text-left max-w-sm">
                    <h3 className="text-sm w-full font-semibold">Clean Drinking Water</h3>
                    <h3 className="text-sm w-full">
                      Bring your own water bottle. Single use water bottles will not be sold at the venue.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex items-center flex-1">
                <div className="flex-1 w-full flex space-x-8 items-center justify-end">
                  <div className="flex-1 w-full flex flex-col items-center justify-start text-right max-w-sm">
                    <h3 className="text-sm w-full font-semibold">Vending and Food</h3>
                    <h3 className="text-sm w-full">There will be food and coffee available for purchase</h3>
                  </div>
                  <GiBowlOfRice className="text-teal-600 w-12 h-12" />
                </div>
              </div>
              <div className="flex items-center flex-1">
                <div className="flex-1 w-full flex justify-start items-center space-x-8">
                  <FaCloudSunRain className="text-sky-400 w-12 h-12" />
                  <div className="flex-1 w-full flex flex-col items-center justify-evenly text-left max-w-sm">
                    <h3 className="text-sm w-full font-semibold">This Party is Rain or Shine!</h3>
                    <h3 className="text-sm w-full">Bring your warm clothes, it can get chilly at night</h3>
                  </div>
                </div>
              </div>
              <div className="flex items-center flex-1">
                <div className="flex-1 w-full flex space-x-8 items-center justify-end">
                  <div className="flex-1 w-full flex flex-col items-center justify-evenly text-right max-w-sm">
                    <h3 className="text-sm w-full font-semibold">Alcohol NOT sold</h3>
                    <h3 className="text-sm w-full">Bring your own beverages. Drink responsibly. Do not bring glass.</h3>
                  </div>
                  <GiBooze className="text-teal-600 w-12 h-12" />
                </div>
              </div>
              <div className="flex items-center flex-1">
                <div className="flex-1 w-full flex justify-center items-center space-x-8">
                  <FaShower className="text-sky-400 w-12 h-12" />
                  <div className="flex-1 w-full flex flex-col items-center justify-evenly text-left">
                    <h3 className="text-sm w-full font-semibold">6 Showers Available</h3>
                    <h3 className="text-sm w-full">
                      Showers are <span className="font-semibold whitespace-nowrap">free</span> to use. No shoes in the
                      shower house
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex items-center flex-1">
                <div className="flex-1 w-full flex justify-end items-center space-x-8">
                  <div className="flex-1 w-full flex flex-col items-center justify-evenly text-right max-w-sm">
                    <h3 className="text-sm w-full font-semibold">Restrooms & Handwashing</h3>
                    <h3 className="text-sm w-full">
                      Handwashing stations and Porta-Potties are available throughout the venue
                    </h3>
                  </div>
                  <FaToiletPaper className="text-teal-600 w-12 h-12" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

const CampingPanelSm = ({ className, campingStyle, children }) => (
  <animated.div
    style={{ opacity: campingStyle.smOpacity }}
    className={`absolute h-full w-full right-0 p-1 pt-32 ${className}`}
  >
    <div className="w-full h-full flex items-center justify-center text-center pt-28">
      <div className="h-full w-full max-w-xl p-3 bg-gray-900 rounded-xl">
        <div className="h-full w-full bg-white rounded-xl relative">{children}</div>
      </div>
    </div>
  </animated.div>
);

const CampingSm1 = ({ campingStyle }) => (
  <animated.div style={{ opacity: campingStyle.co1 }} className="w-full h-full absolute top-0">
    <div className="w-full h-full flex flex-col items-center py-4 justify-start">
      <div className="w-full flex justify-center items-center">
        <GiCampingTent className="text-teal-600 w-20 h-20" />
      </div>
      <div className="flex-1 w-full flex flex-col items-center justify-evenly px-3">
        <h3 className="text-lg w-full font-semibold">There are many beautiful places for you to camp at Neal Creek.</h3>
        <h3 className="text-base w-full">
          These areas will be marked and there will be some lovely people to help you figure out where you might want to
          be.
        </h3>
      </div>
    </div>
  </animated.div>
);

const CampingSm2 = ({ campingStyle }) => (
  <animated.div style={{ opacity: campingStyle.co2 }} className="w-full h-full absolute top-0">
    <div className="w-full h-full flex flex-col items-center py-4 px-1 justify-start">
      <div className="w-full flex justify-center items-center">
        <GiMineWagon className="text-sky-400 w-12 h-12" />
        <MdOutlineFollowTheSigns className="text-sky-400 w-12 h-12" />
      </div>
      <div className="flex-1 w-full flex flex-col items-center justify-evenly px-3">
        <h3 className="text-lg w-full font-semibold">You will have to carry your stuff a short distance.</h3>
        <h3 className="text-base w-full">
          We will have a few carts & wagons at your service to make this chore more enjoyable!
        </h3>
        <ul className="list-disc w-full text-left space-y-4">
          <li className="text-base ml-7 mr-2">
            Tent camping only. <span className="font-semibold whitespace-nowrap">No RVs</span>
          </li>
          <li className="text-base ml-7 mr-2">
            Remember to <span className="font-semibold whitespace-nowrap">pack light</span>
          </li>
          <li className="text-base ml-7 mr-2">
            <span className="font-semibold whitespace-nowrap">Pack out</span> what you
            <span className="font-semibold whitespace-nowrap"> pack in</span>
          </li>
        </ul>
      </div>
    </div>
  </animated.div>
);

const VendingSm1 = ({ campingStyle }) => (
  <animated.div style={{ opacity: campingStyle.co3 }} className="w-full h-full absolute top-0">
    <div className="w-full h-full flex flex-col items-center px-4 py-4">
      <div className="flex-1 w-full flex space-x-2 items-center">
        <div className="flex-1 w-full flex flex-col items-center justify-evenly text-left">
          <h3 className="text-base w-full font-semibold">Vending and Food</h3>
          <h3 className="text-base w-full">There will be food and coffee available for purchase</h3>
        </div>
        <GiBowlOfRice className="text-teal-600 w-12 h-12" />
      </div>
      <div className="flex-1 w-full flex space-x-2 items-center">
        <GiWaterDrop className="text-sky-400 w-12 h-12" />
        <div className="flex-1 w-full flex flex-col items-center justify-evenly text-right">
          <h3 className="text-base w-full font-semibold">Clean Drinking Water</h3>
          <h3 className="text-base w-full">
            Bring your own water bottle. Single use water bottles will not be sold at the venue.
          </h3>
        </div>
      </div>
      <div className="flex-1 w-full flex space-x-2 items-center">
        <div className="flex-1 w-full flex flex-col items-center justify-evenly text-left">
          <h3 className="text-base w-full font-semibold">Alcohol NOT sold</h3>
          <h3 className="text-base w-full">Bring your own beverages. Drink responsibly. Do not bring glass.</h3>
        </div>
        <GiBooze className="text-teal-600 w-12 h-12" />
      </div>
    </div>
  </animated.div>
);

const WeatherSm1 = ({ campingStyle }) => (
  <animated.div style={{ opacity: campingStyle.co4 }} className="w-full h-full absolute top-0">
    <div className="w-full h-full flex flex-col items-center px-4 py-4">
      <div className="flex-1 w-full flex justify-center items-center">
        <FaCloudSunRain className="text-sky-400 w-12 h-12" />
        <div className="flex-1 w-full flex flex-col items-center justify-evenly text-right">
          <h3 className="text-base w-full font-semibold">This Party is Rain or Shine!</h3>
          <h3 className="text-base w-full">Bring your warm clothes, it can get chilly at night</h3>
        </div>
      </div>
      <div className="flex-1 w-full flex justify-center items-center">
        <div className="flex-1 w-full flex flex-col items-center justify-evenly text-left">
          <h3 className="text-base w-full font-semibold">Restrooms & Handwashing</h3>
          <h3 className="text-base w-full">
            Handwashing stations and Porta-Potties are available throughout the venue
          </h3>
        </div>
        <FaToiletPaper className="text-teal-600 w-12 h-12" />
      </div>
      <div className="flex-1 w-full flex  justify-center items-center">
        <FaShower className="text-sky-400 w-12 h-12" />
        <div className="flex-1 w-full flex flex-col items-center justify-evenly text-right">
          <h3 className="text-base w-full font-semibold">6 Showers Available</h3>
          <h3 className="text-base w-full">
            Showers are <span className="font-semibold whitespace-nowrap">free</span> to use.
          </h3>
          <h3 className="text-base w-full">No shoes in the shower house</h3>
        </div>
      </div>
    </div>
  </animated.div>
);

// const NotAllowedSm1 = ({ panelRef }) => {
//   const { opacity13: opacity } = useSpring({ opacity13: 0, ref: panelRef });

//   return (
//     <animated.div style={{ opacity }} className="w-full h-full absolute top-0">
//       <div className="w-full h-full flex flex-col items-center py-4 justify-start">
//         <div className="w-full flex justify-center items-center">
//           <GiCampfire className="text-blue-300 w-20 h-20" />
//           <FaDog className="text-blue-300 w-20 h-20" />
//           <GiSlingshot className="text-blue-300 w-20 h-20" />
//         </div>
//         <div className="flex-1 w-full flex flex-col items-center justify-evenly px-3">
//           <h3 className="text-lg w-full font-semibold">No Fires, No Dogs, No Weapons</h3>
//         </div>
//       </div>
//     </animated.div>
//   );
// };
