import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { useSpringRef } from 'react-spring';
import animationData from '../lottie/tent2.json';

export const TentAnimation = ({ className }) => {
  const containerRef = useRef();
  const tentRef = useRef();
  const springRef = useSpringRef();

  useEffect(() => {
    const loadAnimation = async () => {
      if (tentRef.current) tentRef.current.destroy();
      tentRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    };

    loadAnimation();
  }, [springRef]);

  return <div ref={containerRef} className={className} />;
};
