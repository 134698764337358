import { animated, config, useSpring } from 'react-spring';
import { GiForestCamp, GiDrumKit, GiDirectionSigns, GiFlowerEmblem } from 'react-icons/gi';
import { AiOutlineUnorderedList } from 'react-icons/ai';

const MenuButton = ({ scrollTo, toRef, children, className, offset = 0 }) => {
  const onClick = () => scrollTo(toRef, offset);

  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex items-center space-x-4 px-6 text-2xl text-gray-800 font-medium border-b border-gray-800 py-4 ${className}`}
    >
      {children}
    </button>
  );
};

export const SlideOutMenu = ({ refs, menuOpen, setMenuOpen, scrollTo }) => {
  const { homeRef, lineupRef, campingRef, directionsRef, scheduleRef } = refs || {};

  const style = useSpring({ x: menuOpen ? 0 : -window.screen.width, config: { ...config.stiff, clamp: true } });

  const onClick = (ref, offset) => {
    setMenuOpen(false);
    scrollTo(ref, offset);
  };

  return (
    <animated.div style={style} className="fixed lg:hidden z-30 h-screen overflow-visible">
      <div className="w-screen h-screen bg-white flex flex-col">
        <MenuButton scrollTo={onClick} toRef={homeRef} className="mt-20">
          <GiFlowerEmblem className="w-12 h-12" />
          <div className="w-full flex justify-center">
            <h2 className="-ml-12">Home</h2>
          </div>
        </MenuButton>
        <MenuButton scrollTo={onClick} toRef={lineupRef} offset={0}>
          <GiDrumKit className="w-12 h-12" />
          <div className="w-full flex justify-center">
            <h2 className="-ml-12">Lineup</h2>
          </div>
        </MenuButton>
        <MenuButton scrollTo={onClick} toRef={campingRef} offset={homeRef?.current?.offsetHeight + 20}>
          <GiForestCamp className="w-12 h-12" />
          <div className="w-full flex justify-center">
            <h2 className="-ml-12">Camping</h2>
          </div>
        </MenuButton>
        <MenuButton scrollTo={onClick} toRef={directionsRef} offset={-28}>
          <GiDirectionSigns className="w-12 h-12" />
          <div className="w-full flex justify-center">
            <h2 className="-ml-12">Directions</h2>
          </div>
        </MenuButton>
        <MenuButton scrollTo={onClick} toRef={scheduleRef} offset={-1}>
          <AiOutlineUnorderedList className="w-12 h-12" />
          <div className="w-full flex justify-center">
            <h2 className="-ml-12">Schedule</h2>
          </div>
        </MenuButton>
      </div>
    </animated.div>
  );
};
