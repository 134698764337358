/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($input: CreatePaymentIntentInput) {
    createPaymentIntent(input: $input)
  }
`;
export const confirmPayment = /* GraphQL */ `
  mutation ConfirmPayment($input: ConfirmPaymentInput) {
    confirmPayment(input: $input)
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      amount
      code
      status
      stripeId
      fullName
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      amount
      code
      status
      stripeId
      fullName
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      amount
      code
      status
      stripeId
      fullName
      email
      address
      createdAt
      updatedAt
    }
  }
`;
