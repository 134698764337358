import { animated } from 'react-spring';
// import { ScheduleAnimation } from './ScheduleAnimation';

export const Schedule = ({ refs, scheduleStyle }) => {
  const smallScreen = window.screen.width < 1024;
  const scrollViewHeight = smallScreen ? `600vh` : `400vh`;
  // const textShadow = '0 4px 6px rgba(0,0,0,0.9)';
  const schedules = smallScreen ? (
    <div className="w-full absolute bottom-0">
      <div className="flex flex-col lg:flex-row w-full h-full items-center">
        <img alt="lineup" src="/images/Beltane2023TicketBack.jpg" className="w-1/2 lg:flex-1 h-auto inline" />
        <img alt="lineup" src="/images/WorkshopSchedule.jpg" className="w-full h-auto inline" />
      </div>
    </div>
  ) : (
    <div className="w-full h-full flex justify-between items-center">
      <img alt="lineup" src="/images/Beltane2023TicketBack.jpg" className="w-auto max-h-full" />
      <img alt="lineup" src="/images/WorkshopSchedule.jpg" className="h-2/3" />
    </div>
  );

  return (
    <div ref={refs?.scheduleRef} className="relative z-0">
      <div style={{ height: scrollViewHeight }} className="w-full" />
      {/* <div className="z-10 fixed top-24 left-0 flex justify-center w-full pointer-events-none">
        <animated.h1
          style={{ opacity: scheduleStyle.opacity, textShadow }}
          className="z-10 text-4xl lg:text-7xl font-serif pl-4 -top-2 -mt-2 left-px relative text-white"
        >
          schedule
        </animated.h1>
        <SchedulePanel1 scheduleStyle={scheduleStyle} />
      </div> */}
      <div className="z-0 fixed h-full w-full top-0 right-0 text-9xl pointer-events-none">
        <animated.div
          style={{ opacity: scheduleStyle.opacity }}
          className="absolute h-full w-full right-0 p-1 flex items-center justify-center"
        >
          {/* <div className="w-64 h-64 rounded-full bg-white shadow flex items-center justify-center">
            <span className="text-sm">Check back soon!</span>
          </div> */}
          <div className="w-full h-full flex items-center justify-center text-center pt-20">{schedules}</div>
        </animated.div>
      </div>
    </div>
  );
};

// const SchedulePanel1 = ({ scheduleStyle }) => (
//   <animated.div style={{ opacity: scheduleStyle.opacity }} className="absolute self-center -top-32 pointer-events-none">
//     <div className="h-full w-full flex flex-col justify-start items-center pt-8">
//       <div className="w-56 h-56 lg:w-96 lg:h-96 bg-gray-900 rounded-full shadow-lg bg-opacity-95">
//         <div className="w-full h-full flex flex-col justify-center items-center text-center p-12 xl:p-20">
//           <div className="flex flex-col space-y-4 items-center">
//             <ScheduleAnimation className="w-32 h-32 mt-24 lg:w-56 lg:h-56 lg:mt-32" />
//           </div>
//         </div>
//       </div>
//     </div>
//   </animated.div>
// );
