import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { GiCuckooClock, GiFlowerEmblem } from 'react-icons/gi';
import { FaCarSide, FaCar } from 'react-icons/fa';
import { Honeycomb } from '../svgs/honeycomb';
import { OregonOutline } from './OregonOutline';
import tent from '../svgs/tent.svg';

export const Directions = ({ refs }) => {
  const smallScreen = window.screen.width < 1024;
  const { directionsRef } = refs || {};

  return (
    <div
      ref={directionsRef}
      style={{ background: 'radial-gradient(#01140f 25%, #03291f 90%)' }}
      className="absolute top-0 w-full z-10 overflow-hidden"
    >
      <div className="h-4 w-full bg-gray-900 z-10" />
      <Honeycomb
        id="green"
        className="w-screen h-full absolute stroke-current text-green-900 opacity-50 stroke-1 z-0 pb-8"
      />
      <div className="w-full">
        <div className="w-full flex flex-col items-center min-h-screen py-4 lg:py-20 lg:px-8">
          <div className="flex-1 w-full flex flex-col items-center justify-center lg:pt-0 z-10">
            <div className="w-full flex flex-col items-center justify-evenly h-full">
              <h1 className="text-white text-center ml-10 text-7xl lg:text-9xl font-light font-serif">Directions</h1>
              <div className="flex-1 border-t-2 w-2/3 max-w-sm" />
              <div className="flex-1 h-full flex flex-col items-center space-y-4 text-center px-4 py-12">
                <OregonOutline className="w-64 h-64 text-white flex items-center justify-center">
                  <div className="flex flex-col space-y-2">
                    <h2 className="text-lg font-medium">Neal Creek</h2>
                    <h2 className="text-sm">44644 Camp Morrison Dr.</h2>
                    <h2 className="text-sm">Scio, Oregon 97374</h2>
                  </div>
                </OregonOutline>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-items-stretch w-full max-w-screen-xl flex-1 ">
              <div className="flex-1 h-full flex justify-center items-center">
                <div
                  style={{ width: '100%', height: smallScreen ? '400px' : '90%' }}
                  className="rounded-lg mb-28 lg:mb-0 h-96 relative text-white border-2 border-white overflow-hidden"
                >
                  <BeltaneMap />
                </div>
              </div>
              <div className="hidden lg:flex flex-1 w-full px-4 flex-col space-y-8 items-center z-10 py-8">
                <Parking />
                <ArriveCard />
              </div>
            </div>
          </div>
          <div className="lg:hidden w-full px-4 flex flex-col space-y-8 items-center z-10 py-8">
            <Parking />
            <ArriveCard />
            <ArriveBefore />
          </div>
          <div className="hidden lg:flex w-full px-4 flex-col space-y-8 items-center z-10 pt-20">
            <ArriveBefore />
          </div>
          <div className="flex h-0">
            <a href="mailto:crnaber@gmail.com">Email Clare</a>
            <a href="mailto:josh.garner.dev@gmail.com">Email Josh</a>
          </div>
        </div>
      </div>
      <div className="h-4 w-full bg-gray-900 border-t border-green-900 border-opacity-50" />
      {/* <div className="h-8 w-full mt-8 opacity-90 bg-gradient-to-b from-green-900 mix-blend-darken" /> */}
    </div>
  );
};

// oregon { lat: 43.81, lng: -120.6 }
// beltane { lat: 44.6928834, lng: -122.6323953 }

const libraries = ['places'];

const BeltaneMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries,
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={{ lat: 44.8, lng: -119.4 }} zoom={5}>
      <Marker position={{ lat: 44.6928834, lng: -122.6323953 }} icon={tent} />
    </GoogleMap>
  ) : (
    <></>
  );
};

const Parking = () => (
  <div className="w-full h-full flex flex-col items-center space-y-4 pb-4 text-white text-center">
    <div className="w-full flex justify-center items-center">
      <FaCar className="w-20 h-20" />
    </div>
    <div className="flex-1 w-full flex flex-col items-center space-y-4 px-3">
      <h3 className="text-xl w-full font-semibold">Parking & Loading</h3>
      <h3 className="text-lg w-full">Please come early to skip the traffic, set up camp, and enjoy the woods!</h3>
      <h3 className="text-xl w-full font-semibold">Gates @ Noon on Friday</h3>
    </div>
  </div>
);

const ArriveCard = () => (
  <div className="w-full h-full max-w-sm flex items-center justify-center text-center">
    <div className="h-full w-full p-3 bg-white rounded-xl">
      <div className="h-full w-full flex flex-col items-center space-y-4 bg-emerald-900 rounded-lg p-2 py-4">
        <div className="flex items-center justify-between w-full px-4">
          <h3 className="text-xl font-semibold text-white">When You Arrive</h3>
          <FaCarSide className="text-white w-12 h-12" />
        </div>
        <div className="flex-1 border-t-2 w-2/3 max-w-sm" />

        <div className="flex-1 w-full flex flex-col items-center justify-center pl-2 py-1">
          <ol className="list-decimal list-inside w-full text-left space-y-3 text-white">
            <li className="text-xl">follow signs to check-in</li>
            <li className="text-xl">get your wristband</li>
            <li className="text-xl">unload your gear</li>
            <li className="text-xl">park your car</li>

            <li className="text-xl">
              <span className="text-2xl font-semibold whitespace-nowrap">then</span> pick out a campsite
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);

const ArriveBefore = () => (
  <div className="w-full h-full flex flex-col items-center py-4 text-center text-white space-y-5 pb-4">
    <div className="flex flex-col space-y-3">
      <div className="w-full flex justify-center items-center">
        <GiCuckooClock className="w-20 h-20" />
      </div>
      <h3 className="text-lg w-full"> A Great Reason to Get Here Early</h3>
      <h3 className="text-lg w-full"> if you arrive...</h3>
    </div>
    <div className="border-emerald-900 flex-1 border-t-2 w-2/3 max-w-sm" />
    <div className="flex-1 w-full flex flex-col lg:flex-row max-w-screen-md items-center space-y-6 lg:space-y-0 px-3">
      <div className="flex-1 w-full flex flex-col items-center space-y-2">
        <h3 className="text-2xl">
          <span className="font-semibold">Before</span> 6pm
        </h3>
        <span className="text-lg w-full">unload closer to the campsites</span>
      </div>
      <div className="block lg:hidden border-emerald-900 flex-1 border-t-2 w-2/3 max-w-sm" />
      <div className="flex-1 w-full flex flex-col items-center space-y-2">
        <h3 className="text-2xl">
          <span className="font-semibold">After</span> 6pm
        </h3>
        <span className="text-lg w-full">unload by the main entrance</span>
      </div>
    </div>
    <GiFlowerEmblem className="text-white h-8 w-8" />
  </div>
);
