/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkTicketInventory = /* GraphQL */ `
  query CheckTicketInventory {
    checkTicketInventory
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      amount
      code
      status
      stripeId
      fullName
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        code
        status
        stripeId
        fullName
        email
        address
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
