import { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { GiFlowerEmblem } from 'react-icons/gi';
import { TicketsCheckoutForm } from './TicketsCheckoutForm';
import { checkTicketInventory } from '../graphql/queries';
import { createPaymentIntent } from '../graphql/mutations';

const libraries = ['places'];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const testPI = false;
// const testPI = 'pi_3KVQSPIFjiprUVny1BG07ZfH_secret_gEm5kx31hAC85Mgjahp3oNZEL';

export const Tickets = ({ open, setOpen }) => {
  const [secret, setSecret] = useState(testPI);
  const [ticket, setTicket] = useState(false);

  const onCheckout = (params) => {
    setSecret(params.secret);
    setTicket(params.ticket);
  };

  const onClose = () => {
    setOpen(false);
    setSecret(false);
  };

  if (!open) return <div />;

  const showSuccess = ticket && ticket.status === 'succeeded';

  return (
    <div className="w-full h-full z-50 top-0 absolute bg-gray-900 flex justify-center items-center text-white">
      <button type="button" onClick={onClose} className="absolute top-3 right-4 text-white text-2xl">
        close
      </button>
      {!secret && !showSuccess && <ShoppingCart onCheckout={onCheckout} />}
      {secret && !showSuccess && <PaymentForm ticket={ticket} setTicket={setTicket} secret={secret} />}
      {showSuccess && <SuccessScreen ticket={ticket} />}
    </div>
  );
};

const PaymentForm = ({ ticket, setTicket, secret }) => {
  const appearance = {
    theme: 'night',
    variables: {
      fontFamily: 'Montserrat',
      colorText: '#ffffff',
    },
  };
  const options = {
    clientSecret: secret,
    appearance,
  };

  return (
    <div className="w-full max-w-md p-2">
      <Elements options={options} stripe={stripePromise}>
        <TicketsCheckoutForm ticket={ticket} setTicket={setTicket} secret={secret} />
      </Elements>
    </div>
  );
};

const SuccessScreen = ({ ticket }) => (
  <div className="p-2 flex flex-col items-center space-y-4">
    <h1 className="text-4xl text-white">Payment Success!!</h1>
    <h2 className="text-lg text-white">{`${ticket?.amount} tickets will be mailed out in April`}</h2>
    <h2 className="text-xl text-white">Confirmation Code</h2>
    <h4 className="text-lg text-white p-2 px-4 bg-gray-600 rounded-lg">{ticket?.code}</h4>
    <span className="text-base text-white">Contact us here with questions:</span>
    <span className="text-base text-white">beltaneproductions.pdx@gmail</span>
  </div>
);

const ShoppingCart = ({ onCheckout }) => {
  const [remainingInventory, setRemainingInventory] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [addressFields, setAddressFields] = useState({});
  const [email, setEmail] = useState('');
  const [ticketCount, setTicketCount] = useState(1);
  const updateTicketCount = (val) => setTicketCount(parseInt(val));

  useEffect(() => {
    API.graphql({ query: checkTicketInventory })
      .then(({ data: { checkTicketInventory: resp } = {} }) => resp)
      .then((inv) => {
        setRemainingInventory(inv || 0);
        setLoading(false);
      });
  }, []);

  const createIntent = () => {
    const fields = { ...addressFields, address2 };
    const input = { amount: ticketCount, email, address, fullName, addressFields: fields };

    API.graphql({ query: createPaymentIntent, variables: { input } })
      .then(({ data: { createPaymentIntent: resp } }) => (resp ? JSON.parse(resp) : {}))
      .then(onCheckout);
  };

  const inStock = !loading && remainingInventory > ticketCount;
  const validEmail = email.includes('@') && email.includes('.');
  const disabled = !fullName || !address || !email || !validEmail || !inStock;

  return (
    <div className="flex flex-col w-full max-w-md space-y-4 px-2">
      <div className="w-full flex justify-center pb-4">
        <GiFlowerEmblem className="text-white w-12 h-12" />
      </div>
      <span className="text-white self-center">* Kids 12 and under are free!</span>
      <FullName fullName={fullName} setFullName={setFullName} />
      <TicketCount ticketCount={ticketCount} setTicketCount={updateTicketCount} />
      <EmailAddress email={email} setEmail={setEmail} />
      <ShippingAddress address={address} setAddress={setAddress} setAddressFields={setAddressFields} />
      <Address2 address2={address2} setAddress2={setAddress2} />
      <Totals ticketCount={ticketCount} />

      <button
        type="button"
        disabled={disabled}
        onClick={createIntent}
        className={`text-lg text-white font-medium  w-full rounded-lg shadow p-2.5 ${
          disabled ? 'bg-gray-400' : 'bg-sky-700 hover:bg-blue-800'
        }`}
      >
        <h2>Checkout</h2>
      </button>
    </div>
  );
};

const Totals = ({ ticketCount = 0 }) => {
  const ticketPrice = 22000;
  const calcCharg = (amt) => Math.ceil((amt + 30) / (1 - 0.029) - amt);
  const ticketTotal = (ticketCount || 0) * ticketPrice;
  const feeTotal = ticketCount ? calcCharg(ticketTotal) : 0;
  const total = ticketCount ? ticketTotal + feeTotal : 0;

  const nStr = (num) => (num / 100).toFixed(2);

  return (
    <div className="w-full flex flex-col items-end space-y-1">
      <div className="w-full flex justify-end items-center space-x-2 text-right text-white pr-4">
        <h3 className="text-lg">Tickets:</h3>
        <h3 className="text-lg w-20">{`$${nStr(ticketTotal)}`}</h3>
      </div>
      <div className="w-full flex justify-end items-center space-x-2 text-right text-white pr-4">
        <h3 className="text-lg">Fees:</h3>
        <h3 className="text-lg w-20">{`$${nStr(feeTotal)}`}</h3>
      </div>
      <div className="w-full flex justify-end items-center space-x-2 text-right text-white pr-4">
        <h3 className="text-lg">Total:</h3>
        <h3 className="text-lg w-20">{`$${nStr(total)}`}</h3>
      </div>
    </div>
  );
};

const ShippingAddress = ({ address, setAddress, setAddressFields }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries,
  });

  const inputRef = useRef();
  const onPlaceChanged = () => {
    setAddress(inputRef?.current?.value || '');
    const autocomplete = new window.google.maps.places.AutocompleteService(document.createElement('div'));
    const places = new window.google.maps.places.PlacesService(document.createElement('div'));

    autocomplete.getPlacePredictions({ input: inputRef?.current?.value || '' }, ([place]) => {
      if (place) {
        places.getDetails({ placeId: place.place_id }, ({ address_components: addressArr }) => {
          if (addressArr) {
            const streetNumber = addressArr.find((itm) => itm.types.includes('street_number'))?.short_name;
            const street = addressArr.find((itm) => itm.types.includes('route'))?.short_name;
            const city = addressArr.find((itm) => itm.types.includes('locality'))?.short_name;
            const state = addressArr.find((itm) => itm.types.includes('administrative_area_level_1'))?.short_name;
            const country = addressArr.find((itm) => itm.types.includes('country'))?.short_name;
            const zip = addressArr.find((itm) => itm.types.includes('postal_code'))?.short_name;
            const address1 = `${streetNumber} ${street}`;
            const resp = { address1, city, state, country, zip };

            setAddressFields(resp);
          }
        });
      }
    });
  };

  return (
    <div className="my-4 h-18">
      {isLoaded && (
        <div>
          <Autocomplete onPlaceChanged={onPlaceChanged} fields={['address_components']}>
            <label htmlFor="derp" className="text-white text-base text-opacity-80">
              Shipping
              <input
                id="derp"
                ref={inputRef}
                autoComplete="off"
                value={address || ''}
                onChange={({ target: { value } }) => setAddress(value)}
                type="text"
                style={{ backgroundColor: '#30313D' }}
                placeholder="1972 Shakedown St, Veneta OR"
                className="mt-px mb-3 px-4 py-2.5 border-gray-700 rounded-lg text-white placeholder-white placeholder-opacity-40 w-full"
              />
            </label>
          </Autocomplete>
        </div>
      )}
    </div>
  );
};

const FullName = ({ fullName, setFullName }) => (
  <label htmlFor="fullName" className="text-white text-base text-opacity-80">
    Full name
    <input
      id="fullName"
      value={fullName}
      onChange={({ target: { value } }) => setFullName(value)}
      type="text"
      style={{ backgroundColor: '#30313D' }}
      placeholder="Tom Banjo"
      className="mt-px px-4 py-2.5 border-gray-700 rounded-lg text-white placeholder-white placeholder-opacity-40 w-full"
    />
  </label>
);

const Address2 = ({ address2, setAddress2 }) => (
  <label htmlFor="address2" className="text-white text-base text-opacity-80">
    Apt, suite, etc... (optional)
    <input
      id="address2"
      value={address2}
      onChange={({ target: { value } }) => setAddress2(value)}
      type="text"
      style={{ backgroundColor: '#30313D' }}
      className="mt-px px-4 py-2.5 border-gray-700 rounded-lg text-white placeholder-white placeholder-opacity-40 w-full"
    />
  </label>
);

const EmailAddress = ({ email, setEmail }) => (
  <label htmlFor="emailAddress" className="text-white text-base text-opacity-80">
    Email address
    <input
      id="emailAddress"
      value={email}
      onChange={({ target: { value } }) => setEmail(value)}
      type="text"
      style={{ backgroundColor: '#30313D' }}
      placeholder="beltane@maypole.com"
      className="mt-px px-4 py-2.5 border-gray-700 rounded-lg text-white placeholder-white placeholder-opacity-40 w-full"
    />
  </label>
);

const TicketCount = ({ ticketCount, setTicketCount }) => (
  <label htmlFor="ticketCount" className="text-white text-base text-opacity-80">
    Number of tickets
    <input
      id="ticketCount"
      value={ticketCount}
      onChange={({ target: { value } }) => setTicketCount(value)}
      type="number"
      min="1"
      step="1"
      style={{ backgroundColor: '#30313D' }}
      className="mt-px px-4 py-2.5 border-gray-700 rounded-lg text-white placeholder-white placeholder-opacity-40 w-full"
    />
  </label>
);
