import { useRef, useMemo, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { useGesture } from '@use-gesture/react';
import { Camping, Header, Home, Schedule, Directions, Lineup, SlideOutMenu, Tickets } from './components';

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [ticketsOpen, setTicketsOpen] = useState(false);
  const containerRef = useRef(null);
  const homeRef = useRef(null);
  const lineupRef = useRef(null);
  const campingRef = useRef(null);
  const directionsRef = useRef(null);
  const scheduleRef = useRef(null);

  const { offsetHeight: pHeight = window.screen.height, offsetWidth: pWidth = window.screen.width } =
    containerRef?.current || {};

  const [scheduleStyle, scheduleSpringRef] = useSpring(() => ({ x: 2 * pWidth, opacity: 0 }));
  const [campingStyle, campingSpringRef] = useSpring(() => ({
    x: 2 * pWidth,
    opacity: 0,
    smOpacity: 0,
    co1: 0,
    co2: 0,
    co3: 0,
    co4: 0,
  }));

  const refs = useMemo(
    () => ({ homeRef, lineupRef, campingRef, directionsRef, scheduleRef, containerRef }),
    [homeRef, lineupRef, campingRef, directionsRef, scheduleRef, containerRef]
  );

  const getHeights = () => ({
    home: homeRef?.current?.offsetHeight,
    lineup: lineupRef?.current?.offsetHeight,
    camping: campingRef?.current?.offsetHeight,
    directions: directionsRef?.current?.offsetHeight,
    schedule: scheduleRef?.current?.offsetHeight,
  });

  const getStarts = () => {
    const heights = getHeights();

    return {
      home: 0,
      lineup: heights.home,
      camping: heights.home + heights.lineup,
      directions: heights.home + heights.camping + pHeight,
      schedule: heights.home + heights.camping + heights.directions + pHeight,
    };
  };

  const bind = useGesture({
    onScroll: ({ offset: [_x, y] }) => {
      const starts = getStarts();
      const smallScreen = pWidth < 1024;
      // Camping
      const campingPos = starts.camping - y;
      const campingWidth = pWidth * 1.4;
      const campingHeight = starts.directions - starts.camping - pHeight;
      let campingPercent = Math.round((-campingPos / campingHeight) * 1000) / 1000;

      if (campingPercent < 0) campingPercent = 0;
      if (campingPercent > 1) campingPercent = 1;

      let campingHeaderOpacity = 0;
      const campingX = campingWidth - campingWidth * campingPercent;

      if (campingPos > 0 && campingPos < 100) campingHeaderOpacity = Math.round(100 - campingPos) / 100;
      if (campingPos < 0) campingHeaderOpacity = 1;

      const cardOpacities = { co1: 0, co2: 0, co3: 0, co4: 0, smOpacity: 0 };

      if (smallScreen && campingHeaderOpacity) {
        cardOpacities.smOpacity = 1;

        if (campingPercent <= 0.25) cardOpacities.co1 = 1;
        if (campingPercent > 0.25 && campingPercent <= 0.5) cardOpacities.co2 = 1;
        if (campingPercent > 0.5 && campingPercent <= 0.75) cardOpacities.co3 = 1;
        if (campingPercent > 0.75) cardOpacities.co4 = 1;
      }

      campingSpringRef.start({
        x: campingX,
        opacity: campingHeaderOpacity,
        ...cardOpacities,
      });

      // Schedule
      const schedulePos = starts.schedule - y;
      let scheduleHeaderOpacity = 0;

      if (schedulePos > 0 && schedulePos < 100) scheduleHeaderOpacity = Math.round(100 - schedulePos - 100) / 100;
      if (schedulePos < 0) scheduleHeaderOpacity = 1;

      scheduleSpringRef.start({ opacity: scheduleHeaderOpacity });
    },
  });

  const [_y, setY] = useSpring(() => ({
    from: { y: containerRef?.current?.scrollTop || 0 },
    onChange: ({ value: { y: toY } }) => {
      containerRef.current.scrollTop = toY;
    },
    config: config.slow,
  }));

  const scrollTo = (ref, offset = 0) => {
    let scrollHeight = 0;
    const targetBounds = ref?.current.getBoundingClientRect() || {};

    if (offset === -1) {
      scrollHeight = containerRef.current.scrollTop + targetBounds.bottom - window.screen.height;
    } else {
      scrollHeight = targetBounds.y ? containerRef.current.scrollTop + targetBounds.y + offset : 0;
    }

    setY.start({
      from: { y: containerRef?.current?.scrollTop || 0 },
      to: { y: (scrollHeight || 0) + offset },
    });
  };

  const screenStyle = { minHeight: '-webkit-fill-available' };

  return (
    <div style={screenStyle} className="w-full h-screen overflow-hidden">
      <Tickets open={ticketsOpen} setOpen={setTicketsOpen} />
      <Header
        refs={refs}
        scrollTo={scrollTo}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        setTicketsOpen={setTicketsOpen}
        ticketsOpen={ticketsOpen}
      />
      <SlideOutMenu refs={refs} scrollTo={scrollTo} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
      <animated.div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bind()}
        style={screenStyle}
        ref={containerRef}
        className="w-full h-screen bg-gray-800 overflow-y-scroll overflow-x-hidden fixed"
      >
        <div className="w-full relative">
          <Home refs={refs} pageHeight={pHeight} />
          <div className="w-full relative">
            <div className="relative h-0 w-full overflow-visible mt-8">
              <div className="absolute h-8 -mt-8 w-full top-0 opacity-50 bg-gradient-to-t from-yellow-900 mix-blend-darken" />
            </div>
            <div
              style={{ backgroundImage: 'url(/images/BeltaneNightPath.jpeg)' }}
              className="w-full h-screen bg-cover bg-center sticky top-0 bg-gray-800"
            />
            <div className="absolute top-0 w-full">
              <Lineup refs={refs} pageHeight={pHeight} />
            </div>
            <Camping containerRef={containerRef} refs={refs} pageHeight={pHeight} campingStyle={campingStyle} />
            <div className="w-full relative z-10">
              <div className="relative h-0 w-full overflow-visible mt-8">
                <div className="absolute h-8 -mt-8 w-full top-0 opacity-30 bg-gradient-to-t from-green-900 mix-blend-darken" />
              </div>

              <div
                style={{ backgroundImage: 'url(/images/BeltanePavilionSideStage.jpeg)' }}
                className="w-full h-screen bg-cover bg-center sticky top-0 bg-gray-800"
              />
              <div className="absolute top-0 w-full pb-16">
                <Directions containerRef={containerRef} refs={refs} pageHeight={pHeight} />
              </div>
              <Schedule containerRef={containerRef} refs={refs} scheduleStyle={scheduleStyle} pageHeight={pHeight} />
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default App;
